.MuiButtonBase-root.Mui-selected {
    background: #eee;
}
.status-error,
.status-ko,
.status-ok,
.status-nr {
    padding: 5px;
    border-radius: 4px;
    margin: 0 auto;
}
.status-ko {
    background: #ff0000ad;
    color: #fff;
}
.status-error {
    background: #ff0000ad;
    color: #fff;
}

.status-ok {
    background: #11f111ad;
    color: #fff;
}
.status-nr {
    background: #2a2a2ab0;
    color: #fff;
}
.record-item {
    min-width: 120px;
}
.status-na {
    padding: 5px;
    border-radius: 4px;
    margin: 0 auto;
    background: #eee;
}
/*.profile-card-quota*/
.profile-card {
  margin: 0em auto !important;
  background-size: cover !important;
  box-shadow: none !important;
  height: 100%
}
.profile-card-quota{
    padding: 0 !important;
    margin:  0 !important;
    width:  100%;
    padding: 0px 30px !important;
}
.profile-card {
  max-height: 500px !important;
}

.ellipsis { text-overflow: ellipsis; margin-top: 25px  }

.dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#fab {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999
}

#fab-pdf {
    position: absolute;
    bottom: 20px;
    right: 60px
}
::-webkit-scrollbar {
    width: 0;
    height: 0
}
/*!* width *!*/
/*::-webkit-scrollbar {*/
    /*height: 7px !important;*/
    /*width: 7px !important;*/
    /*border-radius: 2px;*/
/*}*/

/*!* Track *!*/
/*::-webkit-scrollbar-track {*/
    /*!*background: grey;*!*/
/*}*/

/*!* Handle *!*/
/*::-webkit-scrollbar-thumb {*/
    /*!*background: lightgrey;*!*/
/*}*/

/*!* Handle on hover *!*/
/*::-webkit-scrollbar-thumb:hover {*/
    /*background: #555;*/
/*}*/

@font-face {
    font-family: 'Varela Round';
    font-style: normal;
    font-weight: 400;
    src: local('Varela Round'), local('VarelaRound'),
    url('./styles/VarelaRound-Regular.ttf') format('truetype')

}

/*div[class^="TableContainer-root"] {*/
    /*min-height: 60vh !important;*/
/*}*/

/*
* DatePicker calendar
*/
[class^="DayPicker"] table tbody tr td:nth-child(1){
    width: 40px;
    min-width: 40px;
    max-width: 40px;
}
#drawer:hover{
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
.routerLink {
    border-bottom: 1px solid #e4e5e7;
}


.my-legend .legend-title {
    text-align: left;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 90%;
}
.my-legend .legend-scale ul {
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    float: left;
    list-style: none;
}
.my-legend .legend-scale ul li {
    font-size: 80%;
    list-style: none;
    margin-left: 0;
    line-height: 18px;
    margin-bottom: 2px;
}
.my-legend ul.legend-labels li span {
    display: block;
    float: left;
    height: 16px;
    width: 30px;
    margin-right: 5px;
    margin-left: 0;
    border: 1px solid #999;
}
.my-legend .legend-source {
    font-size: 70%;
    color: #999;
    clear: both;
}
.my-legend a {
    color: #777;
}

.exceptional-holiday {
    height: auto;
    width: 100%;
    font-size: 14px;
    background-color: #bce8f1;
    padding: 8px;
    border-left: 4px solid #31708f;
    color: #31708f;
}

/*.appMenu {*/
/*    background: linear-gradient(-45deg, #00b6c2, #aadac5) !important;*/
/*}*/

.appMenu {
    background-color: #3c4b64;
}

.chip-warn {
    background-color: #FF4A46;
    color: white;
    font-size: 10px;
    padding: 3px;
    border-radius: 5px
}
.chip-success {
    background-color: #9CD57F;
    color: white;
    font-size: 10px;
    padding: 3px;
    border-radius: 5px
}

.table{
    padding: 10px;
}
label {
    font-weight: bold;
}
#btn:active {
    /*background: url("./assets/images/222.png") no-repeat;*/
    /*background-size: 50px 50px;*/
}

/*.containerz {*/
/*    position: relative;*/
/*    cursor: pointer;*/
/*    width: 150px;*/
/*    height: 150px;*/
/*    float: left;*/
/*    margin-left: 10px;*/
/*}*/

.checkbox {
    position: absolute;
    bottom: 0;
    right: 0;
}


@media (max-width: 420px) {
    h1 {
        font-size: 13px;
    }

    p {
        font-size: 11px;
    }

    #grid {
        padding-top: 10px;
        padding-right: 4px
    }

    #btn {
        position: relative;
        cursor: pointer;
        width: 110px;
        height: 110px;
        float: left;
        margin-left: 10px;
    }
}

@media (min-width: 400px) {
    #btn {
        position: relative;
        cursor: pointer;
        width: 205px;
        height: 150px;
        float: left;
        margin-left: 10px;
    }


    #grid {
        padding-top: 10px;
        padding-right: 20px
    }
}

.sweet_confirmbuttonImportant {
    font-family: "Yu Gothic";
    font-size: small;
}

.position-absolute {
    position: relative;
    top: 50px;
    left: 30px;
}

span {
    font-family: Roboto;
}
